import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  FBIcon,
  Footer,
  IGIcon,
  Layout,
  TGIcon,
  MetaHeader,
  Breadcrumb,
  ShopeeIcon,
  OrderNowButton,
  LightModalWrapper,
  WhatsappMoreInfoBtn,
} from '@components'

export default function BookDetail({ pageContext, location }: any) {
  const {
    site: {
      siteMetadata: {
        domain,
        social: {
          buku: { fb, ig, shopee, tg },
        },
      },
    },
    book: {
      body,
      frontmatter: {
        date,
        title,
        imgs,
        img: {
          childImageSharp: { gatsbyImageData },
        },
      },
    },
  } = pageContext

  return (
    <Layout>
      <MetaHeader
        url={domain}
        title={title}
        imgUrl={`${domain}${gatsbyImageData.images.fallback.src}`}
        description={`Jom tengok buku ${title}`}
      />
      <div className='min-h-screen flex items-center justify-center'>
        <div className='flex-1 max-w-4xl mx-auto p-10'>
          <Breadcrumb />

          <div className='bg-white shadow-lg border rounded p-10'>
            <div className='flex flex-col lg:flex-row'>
              <h1 className='font-bold text-base sm:text-2xl'>{title}</h1>
              <span className='mt-1 flex text-gray-700 text-xs lg:ml-auto lg:self-end rounded bg-gray-200 py-1 px-2 self-start items-center'>
                {date}
              </span>
            </div>
            <div className='mb-10' />

            <LightModalWrapper altText={title} fluidImgSharp={gatsbyImageData}>
              <GatsbyImage
                image={gatsbyImageData}
                alt={title}
                className='mx-auto'
                style={{
                  maxHeight: '500px',
                  maxWidth: '500px',
                }}
              />
            </LightModalWrapper>

            <div className='mb-10' />

            <div className='prose prose-xl'>
              <MDXProvider components={{}}>
                <MDXRenderer>{body}</MDXRenderer>
              </MDXProvider>
            </div>

            <div className='flex justify-center mt-10 flex-col sm:flex-row'>
              <OrderNowButton shopeeUrl={shopee} />

              <div className='mb-2 sm:mb-0 ml-0 sm:ml-2' />

              <WhatsappMoreInfoBtn
                msg={`Bantu saya dapatkan buku *${title}*, ${location.href}`}
              />
            </div>
          </div>

          <div className='mb-5' />

          {imgs && (
            <ul className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-4 sm:gap-8 grid-flow-row-dense'>
              {imgs.map((item: { id: string } | any, id: number) => (
                <Boxes key={id} item={item} />
              ))}
            </ul>
          )}
        </div>
      </div>

      <Footer>
        <li>
          <IGIcon ig={ig} />
        </li>
        <li>
          <FBIcon fb={fb} />
        </li>
        <li>
          <ShopeeIcon shopee={shopee} />
        </li>
        <li>
          <TGIcon tg={tg} />
        </li>
      </Footer>
    </Layout>
  )
}

export const Boxes = ({ item }: any) => {
  const {
    name,
    childImageSharp: { gatsbyImageData },
  } = item

  const renameImg = name.replace(/(_|-)/g, ' ')

  const aspectRatio = gatsbyImageData.width / gatsbyImageData.height
  const autoSpanGridBasedOnAspectRatio =
    aspectRatio > 1
      ? 'col-span-1 sm:col-span-2'
      : aspectRatio < 1
      ? 'row-span-1 sm:row-span-2'
      : ''

  return (
    <li
      className={`bg-white rounded shadow-md hover:shadow-lg border row-span-1 sm:row-span-2 ${autoSpanGridBasedOnAspectRatio}`}>
      <LightModalWrapper altText={renameImg} fluidImgSharp={gatsbyImageData}>
        <GatsbyImage
          image={gatsbyImageData}
          className='rounded cursor-pointer'
          alt={renameImg}
          imgStyle={{
            height: '100%',
            width: '100%',
          }}
        />
      </LightModalWrapper>
    </li>
  )
}
